import { render, staticRenderFns } from "./CustomisationPopup.vue?vue&type=template&id=5f026608&scoped=true"
import script from "./CustomisationPopup.vue?vue&type=script&lang=js"
export * from "./CustomisationPopup.vue?vue&type=script&lang=js"
import style0 from "./CustomisationPopup.vue?vue&type=style&index=0&id=5f026608&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f026608",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/usr/src/app/components/header/Header.vue').default,Button: require('/usr/src/app/components/button/Button.vue').default,CustomisationFeatures: require('/usr/src/app/components/comfort-suite/customisation/CustomisationFeatures.vue').default,FeatureNotice: require('/usr/src/app/components/comfort-suite/customisation/FeatureNotice.vue').default})
