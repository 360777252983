
import { mapGetters } from 'vuex'
import CustomisationFeatures from './CustomisationFeatures.vue'
import FeatureNotice from './FeatureNotice.vue'
import CrossIcon from '~/assets/icons/cross.svg'

export default {
  name: 'CustomisationPopup',
  components: {
    CrossIcon,
    CustomisationFeatures,
    FeatureNotice,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isOpen: true,
      activeNotice: false,
      currentUrl: this.product?.url,
      targetProduct: {},
    }
  },
  computed: {
    ...mapGetters({
      generalSettings: 'storyblok/settings',
    }),
    trackData () {
      const data = []
      const customisationFeatures = this.generalSettings?.customisation_features

      for (const feature of customisationFeatures) {
        if (this.product?.[feature?.tracking_key] !== this?.targetProduct?.[feature?.tracking_key]) {
          const toggleValues = feature?.toggle_values?.split(',')
          const sourceProduct = this.getProductTrackingData(this.product, feature.tracking_key, toggleValues)
          const targetProduct = this.getProductTrackingData(this.targetProduct, feature.tracking_key, toggleValues)
          data.push({
            source_product: sourceProduct,
            target_product: targetProduct
          })
        }
      }

      return data
    }
  },
  methods: {
    getProductTrackingData (product, key, toggleValues) {
      return {
        name: product?.name,
        sku: product?.sku,
        url: product?.url,
        toggleValue: product[key] ? toggleValues[0] : toggleValues[1]
      }
    },
    hideCustomisationPopup () {
      this.$store.dispatch('ui/hideCustomisationPopup')
    },
    handleSubmit () {
      if (this.product.url === this.currentUrl) {
        this.hideCustomisationPopup()
      } else {
        for (const feature of this.trackData) {
          this.gtm_trackComfortSuiteClickEvent('comfort_suite_click', feature, 'CustomisationPopup')
        }
        this.$router.push(this.currentUrl)
        this.hideCustomisationPopup()
      }
    },
    showNotice (value) {
      this.activeNotice = value
    },
    setIsOpen (isOpen) {
      this.isOpen = isOpen
    },
    setUrl (url) {
      this.currentUrl = url
    },
    setTargetProduct (product) {
      this.targetProduct = product
    },
  },
}
